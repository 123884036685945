<template>
  <h4>
    SnpFrequencyList
  </h4>
</template>

<script>
import SelectSnpFrequencyEditInteractor from "@/business/snp-frequency-list/select-snp-frequency-edit";
import SelectCreateInteractor from "@/business/snp-frequency-list/select-create";
import ChangeFilterInteractor from "@/business/snp-frequency-list/change-filter";
import InitSnpFrequencyListScreenInteractor from "@/business/snp-frequency-list/init-snp-frequency-list-screen";
import SnpFrequencyListScreenController from "@/adapters/controllers/screen-snp-frequency-list";

export default {
  screen_name: "snp-frequency-list",
  data() {
    return {
      controller: null,
      interactors: {
        selectSnpFrequencyEdit: null,
        selectCreate: null,
        changeFilter: null,
        initSnpFrequencyListScreen: null
      },
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(SnpFrequencyListScreenController);

    //{ INTERACTORS
    this.interactors.initSnpFrequencyListScreen = this.$injector.get(
      InitSnpFrequencyListScreenInteractor
    );
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectSnpFrequencyEdit = this.$injector.get(
      SelectSnpFrequencyEditInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
  },
  mounted() {},
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    }
  }
};
</script>

<style lang="scss"></style>
